<template>
  <div class="content_box">
    <div class="content_box_title">
      <div @click="returnPage"><i class="iconfont">&#xe625;</i>设置密码</div>
    </div>
    <div class="content_box_edit">
      <div class="content_box_explain">
        请设置账号密码。你可以用账号绑定的账号+账号密码登录，比如使用手机号/学号+账号密码登录账号，更快捷。
      </div>
      <div class="content_box_edit_input">
        <span>手机号</span>
        <span class="content_box_edit_div">{{ mobile }}</span>
      </div>
      <div class="content_box_edit_input">
        <span>验证码</span>
        <input
          class="input_code"
          type="text"
          v-model="list.code"
          maxlength="4"
          placeholder="请输入验证码"
        />
        <button @click="codeBut" v-if="codeLabel">{{ codeName }}</button>
        <div v-else>{{ codeName }}</div>
      </div>
      <div class="content_box_edit_input">
        <span>设置密码</span>
        <input
          type="password"
          v-model="list.password"
          maxlength="20"
          placeholder="请设置6-20位数字、字符组合密码"
        />
      </div>
    </div>
    <button class="content_box_but" @click="determineBut">确定</button>
  </div>
</template>

<script>
import { pwdCode, resetPwd, getUserInfo } from "@/api/personal";
export default {
  data() {
    return {
      list: { code: "", password: "" },
      codeName: "获取验证码",
      codeLabel: true,
      mobile: "",
    };
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    //返回上一页
    returnPage() {
      this.$router.push({
        path: "myStudy",
        query: { option: 5, path: "accountSettings" },
      });
    },
    //获取个人信息
    getUserInfo() {
      getUserInfo().then((res) => {
        if (res.code == 200) {
          this.mobile = res.data.mobile;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //验证码
    codeBut() {
      let that = this;
      that.codeLabel = false;
      pwdCode().then((res) => {
        if (res.code == 200) {
          var count = 60;
          var timer = setInterval(function () {
            count--;
            if (count <= 0) {
              clearInterval(timer);
              that.codeName = "重新发送";
              that.codeLabel = true;
            } else {
              that.codeName = count + "s";
            }
          }, 1000);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //确认
    determineBut() {
      let that = this;
      if (that.list.password.length < 6) {
        this.$message.error("请设置6-20位数字、字符组合密码");
        return false;
      } else if (that.list.code.length != 4) {
        this.$message.error("请输4位正确的验证码");
        return false;
      } else {
        resetPwd(that.list).then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "设置成功",
              type: "success",
            });
            setTimeout(() => {
              this.$router.push({
                path: "myStudy",
                query: { option: 5, path: "accountSettings" },
              });
            }, 1000);
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.content_box {
  height: 748px;
}

.content_box_title {
  padding-left: 33px;
  border-bottom: 1px solid #eeeeee;
  text-align: left;
  & > div {
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    line-height: 60px;
    height: 60px;
    width: 110px;
    cursor: pointer;
    & > .iconfont {
      font-size: 18px;
      padding-right: 12px;
    }
  }
}

.content_box_edit {
  margin-top: 105px;
  & > .content_box_explain {
    width: 356px;
    height: 33px;
    font-size: 12px;
    font-weight: 500;
    color: #999999;
    line-height: 20px;
    margin: 0 auto;
    margin-bottom: 39px;
    text-align: left;
  }
  & > .content_box_edit_input {
    margin-bottom: 23px;
    & > span {
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      line-height: 38px;
      margin-right: 21px;
      width: 60px;
      display: inline-block;
      vertical-align: top;
    }
    & > .content_box_edit_div {
      width: 295px;
      height: 38px;
      font-size: 14px;
      font-weight: 500;
      color: #0d79ff;
      line-height: 38px;
      text-align: left;
      margin-right: 0;
      display: inline-block;
      vertical-align: top;
    }
    & > input {
      width: 295px;
      height: 38px;
      background: #ffffff;
      border: 1px solid #e6e6e6;
      border-radius: 2px;
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      line-height: 38px;
      padding-left: 10px;
      display: inline-block;
      vertical-align: top;
    }
    & > .input_code {
      width: 198px;
    }
    & > button {
      width: 96px;
      background: #fff;
      border: 1px solid #e6e6e6;
      border-left: 0px;
      font-size: 14px;
      font-weight: 500;
      color: #0d79ff;
      height: 38px;
      display: inline-block;
      vertical-align: top;
    }
    & > div {
      width: 96px;
      background: #fff;
      border: 1px solid #e6e6e6;
      border-left: 0px;
      font-size: 14px;
      font-weight: 500;
      color: #0d79ff;
      line-height: 38px;
      height: 38px;
      display: inline-block;
      vertical-align: top;
    }
  }
}

.content_box_but {
  width: 295px;
  height: 40px;
  background: #0d79ff;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  line-height: 40px;
  margin-left: 73px;
}
</style>